import React from "react";
import { Link } from "gatsby";
import Layout from "~components/base/layout";
import SEO from "~components/base/seo";
import Accordion from "~components/accordion/accordion";
import Products from "~components/products/products";
import "~styles/pages/services/_website.scss";
import TypeAnimation from "react-type-animation";

const EmailMarketing = () => (
  <Layout type="template-website">
    <SEO
      title="Email And Automated Marketing Services "
      description="Segment your audience, tailor your campaigns, and nurture your leads to become repeat customers with our email and automated marketing services."
    />
    <section className="page-heading">
      <div className="container">
        <TypeAnimation
          cursor={false}
          sequence={["Email & Automation Marketing Services", 500]}
          className={"title"}
          wrapper="h1"
        />
        <div className="p-mb">
          <p>
            As you grow, you will want to be able to intelligently and
            accurately segment your audience and community to effectively adjust
            and tailor your campaigns, perform research, and lead innovation.
            This can be done through marketing automation software, and, on a
            more basic level, email marketing.
          </p>
          <p>
            The earlier you build this practice, the easier it is to leverage
            when you scale 10x, 15x, and 100x.  
          </p>
        </div>
      </div>
    </section>
    <section>
      <div className="container red-accordion" id="email-marketing">
        <Accordion>
          <div label="Setup Packages | $2,800 CAD - $6,900 CAD" subtitle="">
            <p>
              If you don’t have an email marketing system in place or your
              system doesn’t work – this is where you need to start. A solid
              foundation, which starts with clearly defined personas, will
              ensure that everything you do builds momentum and helps you gain
              insight into your audience and their personal buying journey.
            </p>
            <Products type="slim">
              <div
                title="Basic Setup Package | $2,800 CAD"
                id="email-setup-basic"
                subtitle=""
                extrainfo="All the research, planning, setup & training you need for a basic functioning automated email and marketing system, plus the creation of 1 automated welcome flow."
              ></div>
              <div
                title="Essentials Setup Package | $4,600 CAD"
                id="email-setup-setup"
                subtitle=""
                extrainfo="All the research, planning, setup & training you need for a solid starting point for an automated email and marketing system, plus the creation of 3 automated email workflows."
              ></div>
              <div
                title="Advanced Setup Package | $6,900 CAD"
                id="email-setup-advanced"
                subtitle=""
                extrainfo="All the research, planning, setup & training you need for a cohesive automated email and marketing system, plus the creation of 5 automated email workflows."
              ></div>
            </Products>
          </div>
          <div
            label="Monthly Packages | $750 CAD - $3,800 CAD/month"
            subtitle=""
          >
            <p>
              Got an email marketing tool in place but no time to plan, create,
              curate, schedule, send, and test content? These monthly packages
              have been designed for different types of businesses (and their
              related audiences) to deliver the content and experience your
              customers need.
            </p>
            <Products type="slim">
              <div
                title="Small Email Package | $750 CAD/month "
                id="email-monthly-small"
                subtitle="+ one-time $375 CAD project fee"
                extrainfo="Basic content to encourage light engagement and share news/updates. Best for independent health and wellness providers, independent professional services like lawyers and accountants, and consultancy-styled businesses OR can be used for internal company news and information. Does not include any automated workflows."
              ></div>
              <div
                title="Medium Email & Automated Marketing Package | $1,900 CAD/month"
                id="email-monthly-medium"
                subtitle="+ one-time $375 CAD project fee"
                extrainfo="Consistent content for a regularly engaged audience, plus slowly build on your automated marketing system’s foundation with 1 automated workflow per month and support included. Best for professional service providers such as real estate professionals, insurance providers, mortgage brokers and more."
              ></div>
              <div
                title="Large Email & Automated Marketing Package | $3,800 CAD/month"
                id="email-monthly-large"
                subtitle="+ one-time $375 CAD project fee"
                extrainfo="Dynamic, time-sensitive content for a highly engaged audience who makes frequent online or in-person purchases. Quickly build out your automated systems with 2 automated flows per month, plus additional integrations work. Best for retailers (online and offline), restaurants, online courses, monthly membership providers, and more."
              ></div>
            </Products>
          </div>
        </Accordion>
      </div>
    </section>
    <section id="email-marketing-addons">
      <div className="container">
        <div className="website-addons p-mb">
          <h2>Email & Automated Marketing Add-Ons</h2>
          <p>
            Marketing, sales cycles, and user journeys can often be seasonal, or
            you may need a little extra support at different phases of your
            growth. Check out our optional add-ons for when you need a little
            extra support at specific times.
          </p>
        </div>
        <div className="blue-outline website-addonlist-wrapper">
          <Accordion>
            <div
              label="Additional Newsletter Email"
              subtitle="$550 CAD/per email"
            >
              <p>
                Have a press release or product launch to share? Maybe need
                email support for a series of webinars that you’re releasing in
                the Fall? If you’ve already purchased a monthly email plan with
                us, you can add-on additional email newsletters in the months
                you need them.
                <br></br>
                <Link
                  className="btn btn-dark"
                  to="/services/addons/additional-email-newsletter"
                >
                  LEARN MORE
                </Link>
              </p>
            </div>
            <div
              label="Additional Automated Workflow"
              subtitle="$1,050 CAD/per flow"
            >
              <p>
                Automated workflows take on the heavy lifting and free up you
                and your team’s time. Add additional automated workflows to your
                monthly email package when you need to launch a new product,
                create a new experience for a new or existing audience or widen
                the conversion points in your funnel. <br></br>
                <Link
                  className="btn btn-dark"
                  to="/services/addons/additional-automatedworkflow"
                >
                  LEARN MORE
                </Link>
              </p>
            </div>
            <div
              label="Additional Integrations Support Hours"
              subtitle="$115 CAD/hour"
            >
              <p>
                Integrations can be messy. If you need to make something work
                but can’t figure out how to get your systems talking to each
                other, add some additional IT support hours to your existing
                monthly plan. <br></br>
                <Link
                  to="/services/addons/additional-integrations-support-hours"
                  className="btn btn-dark"
                >
                  LEARN MORE
                </Link>
              </p>
            </div>
          </Accordion>
        </div>
      </div>
    </section>
    <section id="faq" className="faq-wrapper">
      <div className="container">
        <h2>FAQ</h2>
        <Accordion>
          <div label="What is marketing automation software?">
            <p>
              Marketing automation software comes in a variety of shapes, sizes,
              capabilities, and prices. At its core, marketing automation
              software lets you automate specific tasks that would otherwise
              have to be done by a human. This could be anything from notifying
              your sales team of a new lead in your CRM to sending a text
              message to everyone between the ages of 30-35 who regularly visits
              your North Vancouver location on Wednesday nights between the
              hours of 5pm and 6:30pm.
              <br></br>
              <br></br>
              Some examples of different marketing automation software include
              (but are not limited to) Hubspot, SendGrid, CampaignMonitor,
              Mailchimp, Drip, ActiveCampaign, Klaviyo, Moosend, and who knows
              how many more have popped up since we started typing this
              sentence!<br></br>
              <br></br>
              Then there are a whole bunch of integrations, plugins, and helper
              tools like Automate.io and Zapier that bring it all together and
              allow different systems to talk to one another.
            </p>
          </div>
          <div label="Do I need automated workflows?">
            <p>
              Well, it depends. Do you find yourself doing the same repetitive
              task over and over (anything from updating contact information to
              sending emails)? Do you use your CRM to manage deal stages and
              sales pipelines? Do you have a mailing list but you’re not doing
              anything with it? Do your customers expect an instant
              response/information/access post-purchase?<br></br>
              <br></br>If you’re unsure what you may or may not need, we
              recommend starting a live chat with one of our marketing
              specialists <span id="intercombot-link">here.</span>{" "}
            </p>
          </div>
        </Accordion>
      </div>
    </section>
  </Layout>
);

export default EmailMarketing;
